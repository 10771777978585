import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const ProjectInfocard = ({
	width = 56,
	height = 56,
	...restProps
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			{...restProps}
			viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_340_6027)">
				<path d="M17.8884 19.9252L0.726644 9.98686L17.9137 0.387214L35.0754 10.3255L17.8884 19.9252Z" fill="#A774F6" />
				<g clip-path="url(#clip1_340_6027)">
					<path d="M18.0435 19.7052L17.984 39.5117L1.33966 29.9062L1.39921 10.0997L18.0435 19.7052Z" fill="#898989" />
					<path d="M34.5807 10.0997L34.5212 29.9062L17.984 39.5117L18.0436 19.7052L34.5807 10.0997Z" fill="#C5C5C5" />
					<path d="M17.9304 0L35.4203 10.0997L18.0435 20.1935L0.553589 10.0997L17.9304 0ZM18.0435 19.7052L34.5807 10.0938L17.9363 0.488313L1.3992 10.0997L18.0435 19.7052Z" fill="#7C2FF1" />
					<path d="M18.0435 20.1935L17.99 40L0.5 29.9062L0.553595 10.0997L18.0435 20.1935Z" fill="#A873F5" />
					<path d="M35.4204 10.0997L35.3668 29.9062L17.99 40L18.0436 20.1935L35.4204 10.0997Z" fill="#7C2FF1" />
					<path d="M17.6827 10.4262C17.6836 10.0875 17.2098 9.8115 16.6244 9.80984C16.039 9.80818 15.5636 10.0814 15.5627 10.4202C15.5617 10.759 16.0355 11.0349 16.6209 11.0366C17.2064 11.0382 17.6817 10.765 17.6827 10.4262Z" fill="#7C2FF1" />
					<path d="M13.0234 11.654C13.4343 11.4158 14.1073 11.4099 14.5241 11.654C14.941 11.8922 14.9469 12.2853 14.5301 12.5235C14.1192 12.7617 13.4462 12.7676 13.0294 12.5235C12.6125 12.2853 12.6125 11.8922 13.0234 11.654Z" fill="#7C2FF1" />
					<path d="M23.3773 7.10212C23.3782 6.76336 22.9044 6.4874 22.319 6.48574C21.7336 6.48408 21.2582 6.75735 21.2573 7.0961C21.2563 7.43486 21.7301 7.71082 22.3155 7.71248C22.9009 7.71414 23.3763 7.44087 23.3773 7.10212Z" fill="#7C2FF1" />
					<path d="M20.53 8.76422C20.5309 8.42547 20.0571 8.14951 19.4717 8.14785C18.8863 8.14619 18.4109 8.41946 18.41 8.75821C18.409 9.09697 18.8828 9.37293 19.4682 9.37459C20.0537 9.37624 20.529 9.10297 20.53 8.76422Z" fill="#7C2FF1" />
				</g>
			</g>
			<defs>
				<clipPath id="clip0_340_6027">
					<rect width="36" height="40" fill="white" />
				</clipPath>
				<clipPath id="clip1_340_6027">
					<rect width="34.9204" height="40" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>

	);
};
