import * as React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Tooltip,
  HStack,
  Link as ChakraLink,
  useToast,
  Text,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  IconButton,
  Table,
  Thead,
  Select,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Avatar,
  TableCaption,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
} from "@chakra-ui/react";
import { Paginator } from '../../widgets/Paginator/Paginator'
import { Link, NavLink, Route, Switch, useHistory } from "react-router-dom";
import { User } from '../../services/appConfig';
import { CurrentUser } from "../../services/appConfig";
import { EmailPlusIcon } from "../../components/Icons/Icon";
import { IoIosSearch } from "react-icons/io";
import styles from './Users.module.scss';
import { FiMoreHorizontal } from "react-icons/fi";
import LastCrawledFormate from "../../utils/LastCrawledFormate";
import { UserSquare } from "@phosphor-icons/react";
import Pagination from "./Pagination";
import Rectangle from "./Rectangle.png";
import { getAllUsers, SearchUsers, adminDeleteUser } from "../../services/knowledgebaseService";
import { formatNumber } from "../../utils/commonUtils";
export const Users = () => {
  const toast = useToast();
  const [chatbotsList, setChatBotList] = React.useState<any>();
  const [chatbotsLoaded, setChatbotsLoaded] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User>(CurrentUser.get());
  const [users, setUsers] = React.useState<any>([]);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const history = useHistory();
  const [createChatBotLoading, setCreateChatBotLoading] = React.useState({})
  const [itemsPerPages, setItemsPerPage] = React.useState<number>(10);
  const [isDeleteDialogOpens, setIsDeleteDialogOpens] = React.useState(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [userId, setUserId] = React.useState<any>();
  const [shouldFetchUsers, setShouldFetchUsers] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const userData = await getAllUsers(itemsPerPages, currentPage);
        setUsers(userData.data);
      } catch (error) {
        console.error(error);
      }
    }

    if (shouldFetchUsers) {
      fetchData();
      setShouldFetchUsers(false); // Reset the trigger
    }
    fetchData();
  }, [shouldFetchUsers, currentPage, itemsPerPages]);



  const handlePageChange = (page: number) => {
    let pages: number = page + 1;
    setCurrentPage(pages);
  };
  const [searchValue, setSearchValue] = React.useState('');

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value); // Update state with the input value
  };

  const handleItemsPerPageChange = (event: any) => {

    setItemsPerPage(parseInt(event.target.value, 10));

  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        // const userData = CurrentUser.get();
        const userData = await SearchUsers(itemsPerPages, currentPage, searchValue);

        setUsers(userData.data);

      } catch (error) {

      } finally {
      }
    }
    fetchData();
  }, [searchValue]);

  const handleCancelDeletes = () => {
    setIsDeleteDialogOpens(false);
  };

  const deleteUser = (id: any) => {
    setIsDeleteDialogOpens(true);
    setUserId(id)
  }

  const handleConfirmDeletes = async () => {
    setIsDeleteDialogOpens(false);
    await adminDeleteUser(userId);
    setShouldFetchUsers(true); // Trigger the fetch
  };

  return (
    <Box
      w="100%"
      p="6"
      shadow="sm"
      h="100%"
      overflow="auto"

    >
      <VStack w="100%" spacing="10">
        <Flex shrink={0} w="100%" justifyContent="space-between">
          <Flex alignItems="center">
            <Heading fontSize="30">Users</Heading>
            <Box marginLeft="15px">
            </Box>
          </Flex>
          <Flex>
            <Text as="span" marginRight="12px">
              <InputGroup
                width="400px"
                // border="1px solid #6C757D"
                borderRadius="4px"
                borderColor="#6C757D"
              >
                <InputLeftElement pointerEvents='none'>
                  <IoIosSearch color="#6C757D" />
                </InputLeftElement>
                <Input type='text' placeholder='Search for url, client & email id' color="#6C757D" value={searchValue}
                  onChange={handleInputChange} />
              </InputGroup>
            </Text>
            <Button color={"white"} backgroundColor={'#7C2FF1'} size='md' className="btn-hover-pri" onClick={() => window.location.href = "/app/inviteuers"}><EmailPlusIcon />Invite Users</Button>
          </Flex>
        </Flex>
      </VStack>
      <Box mt="24px" pt={12} pb={1} backgroundColor="white" boxShadow="sm" borderRadius="8px" minW="100%" w="600px">
        <Text as="div">
          <TableContainer overflow="auto" w="100%">
            <Table variant="simple" >
              <Thead>
                <Tr className={styles.tableHd}>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Projects</Th>
                  <Th>Plan</Th>
                  <Th>Usage</Th>
                  <Th>Joined On</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.allUsersProfileData ? users.allUsersProfileData.map((user: any, index: number) => (
                  <Tr key={index} className={styles.tableTd}>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Flex align="center">
                        <Avatar size="sm" src={user.avatarUrl} h="40px" w="40px" borderRadius="6px" />
                        <Text color="#1A202C" fontWeight="500" ml="12px" textTransform="capitalize">{user.name}</Text>
                      </Flex>
                    </Td>
                    <Td>{user.email} {user.isActive === 0 ? <Text as="span" ml="15px" fontSize="10px" className={styles.projectCount}>Deleted</Text> : null}</Td>
                    <Td>{user.project}</Td>
                    <Td>{user?.subscriptionData?.name}</Td>
                    <Td>
                      {user?.monthUsage?.count ? `${formatNumber(user.monthUsage.count)} / ${formatNumber(user?.subscriptionData?.maxTokens)}` : `0 / ${formatNumber(user?.subscriptionData?.maxTokens)}`}
                    </Td>
                    <Td><LastCrawledFormate date={user.createdAt} /></Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          minW='8'
                          minH='8'
                          h="8"
                          aria-label='Options'
                          icon={<FiMoreHorizontal />}
                          color="#212529"
                          variant='outline'
                          backgroundColor="#F8F9FA"
                        />
                        <MenuList>
                          <Link to={`/app/users/profile/${user._id}`}>
                            <MenuItem fontSize="14px" color="#1A202C" fontWeight="500">
                              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* SVG paths */}
                              </svg>
                              <Text as="span" ml="12px">View Profile</Text>
                            </MenuItem>
                          </Link>

                          <MenuItem fontSize="14px" color="#1A202C" fontWeight="500" onClick={() => deleteUser(user._id)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              {/* SVG paths */}
                            </svg>
                            <Text as="span" ml="12px">Delete</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                )) : null}
              </Tbody>
            </Table>
          </TableContainer>
        </Text>
      </Box>
      <Box>
        {/* <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        /> */}
        <HStack w="100%" justifyContent="space-between" p={4}>
          <Flex alignItems="center">
            <Text mr="8px" fontSize="16px" fontWeight="400" color="#212529">Total {users.totalCount} items</Text>
            <Paginator onPageChange={handlePageChange} pageRangeDisplayed={5} pageCount={users.pages} />
          </Flex>
          <Flex alignItems="center">
            <Select
              value={itemsPerPages}
              onChange={handleItemsPerPageChange}
              width="auto"
              color="#6C757D"
              mr="8px"
            >
              <option value={10}>10 / page</option>
              <option value={20}>20 / page</option>
              <option value={50}>50 / page</option>
            </Select>
          </Flex>
        </HStack>
      </Box>
      <AlertDialog
        isOpen={isDeleteDialogOpens}
        leastDestructiveRef={cancelRef}
        onClose={handleCancelDeletes}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this user?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} size='sm' onClick={handleCancelDeletes}>
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                variant="solid"
                onClick={handleConfirmDeletes}
                ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box >
  );
};
