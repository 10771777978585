import * as React from 'react';
import {
	Box,
	BoxProps,
	Button,
	Flex,
	Heading,
	HStack,
	IconButton,
	Image,
	VStack,
	Menu,
	Stack,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay
} from '@chakra-ui/react';
import { FiMoreHorizontal } from "react-icons/fi"
import styles from './MediaListItem.module.scss';
import LastCrawledFormate from "../../utils/LastCrawledFormate";
import { useEffect, useState, useRef } from 'react';
import { DefaultMediaImage } from '../DefaultMediaImage/DefaultMediaImage';
import { ProjectInfocard } from "../DefaultMediaImage/ProjectInfocard";
import { formatNumber } from "../../utils/commonUtils";
interface MediaListItemProps extends BoxProps {
	imageUrl: string;
	name: string;
	owner: string;
	updatedAt: string;
	websiteData: string;
	imageAlt: string;
	description: string;
	showWarning: boolean;
	showCustomizeMenu: boolean;
	showGetCodeMenu: boolean;
	isPrimaryButtonLoading?: boolean;
	actionButtonLabel?: string;
	showPrimaryActionButton?: boolean;
	onPrimaryActionButtonClick?: () => void;
	onMenuItemClick?: (type: any) => void;
	actionButtonLeftIcon?: React.ReactNode;
	onActionButtonClick?: () => void;
}

export const MediaListItem = ({ onMenuItemClick, showWarning, showPrimaryActionButton, isPrimaryButtonLoading, onPrimaryActionButtonClick, className, ...restProps }: MediaListItemProps) => {
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	// const cancelRef = useRef();
	const cancelRef = useRef<HTMLButtonElement>(null)
	const handleDelete = () => {
		setIsDeleteDialogOpen(true);
	};

	const handleConfirmDelete = () => {
		setIsDeleteDialogOpen(false);
		onSelect("delete");
	};

	const handleCancelDelete = () => {
		setIsDeleteDialogOpen(false);
	};
	useEffect(() => {
		async function fetchData() {
			try {
			} catch (error) {

			} finally {
			}
		}
		fetchData();
	}, []);

	const onSelect = React.useCallback((type) => {
		onMenuItemClick && onMenuItemClick(type)
	}, [onMenuItemClick]);

	return (
		<>
			<Stack
				shadow='xs'
				p='4'
				bg={'white'}
				borderRadius='lg'
				className={className || '' + ' ' + styles.container}
				spacing='4'
				align='start'
				w={'100%'}
				flexShrink={0}
				justify='space-between'
				alignItems='start'
			>
				<HStack
					justify='space-between'
					position="relative"
					width="100%"
				>
					<HStack

					>
						<Stack spacing={4}>
							<Box>
								{restProps.imageUrl ? (
									<Image
										borderRadius='lg'
										objectFit="cover"
										src={restProps.imageUrl}
										alt={restProps.imageAlt}
									/>
								) : (
									// <DefaultMediaImage />
									<ProjectInfocard />
								)}
							</Box>
							<Flex
								alignSelf='start'
								direction='column'
								mt="15px"

							>
								<Heading cursor="pointer" mb='8px' fontSize='14px' fontWeight='500'
									color='#1A202C' onClick={() => {
										onSelect('edit')
									}}>
									{restProps.name}{restProps.owner === null ? <Text as="span" ml="15px" fontSize="10px" className={styles.projectCount}>Trial</Text> : null}
								</Heading>
								{/* {restProps.description && <Text noOfLines={2} fontSize='sm' color='gray.500' dangerouslySetInnerHTML={{ __html: restProps.description }} >
								</Text>} */}
								{/* {
									showPrimaryActionButton && (<Box mt="4">
										<Button onClick={onPrimaryActionButtonClick} isLoading={isPrimaryButtonLoading}
											loadingText='Creating chat bot' colorScheme="blue" size='xs'>
											Create chatbot
										</Button>
									</Box>)
								} */}
								<Heading
									cursor="pointer"
									mb='8px'
									fontSize='12px' // Directly using the desired font-size
									fontWeight='400'
									color='#6C757D' // Directly using the desired color
								>
									{restProps.websiteData}
								</Heading>
							</Flex>
						</Stack>


					</HStack>
					<HStack
						position="absolute"
						top="0%"
						right="0%"
					>
						<Box alignSelf='end'>
							{restProps.actionButtonLabel ? (
								<Button
									{...{
										[restProps.actionButtonLeftIcon ? 'leftIcon' : '']:
											restProps.actionButtonLeftIcon,
									}}
									onClick={restProps.onActionButtonClick}
									size='sm'
									variant='outline'
								>
									{restProps.actionButtonLabel}
								</Button>
							) : <Menu placement="bottom-end">
								<MenuButton
									as={IconButton}
									minW='8'
									minH='8'
									h="8"
									aria-label='Options'
									icon={<FiMoreHorizontal />}
									color="#212529"
									variant='outline'
									backgroundColor="#F8F9FA"
								/>
								<MenuList minW="140px">
									<MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={() => {

										onSelect('edit')
									}}>
										Edit
									</MenuItem>
									{restProps.showCustomizeMenu ? <MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={() => {
										onSelect('customize')
									}}>
										Customize
									</MenuItem> : null}
									{restProps.showGetCodeMenu ? <MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={() => {
										onSelect('getCode')
									}}>
										Get code
									</MenuItem> : null}
									<MenuItem fontSize="14" textAlign="right" fontWeight="medium" color="gray.600" onClick={handleDelete}>
										Delete
									</MenuItem>
								</MenuList>
							</Menu>}
						</Box>
					</HStack>
				</HStack>
				<Box mt="5px" width="100%">
					<hr style={{ opacity: "40%" }} />
					<Box
						display="flex"
						justifyContent="space-between"
						mt="10px"
					>
						<HStack>

							<Heading
								cursor="pointer"
								mb='8px'
								fontSize='12px' // Directly using the desired font-size
								fontWeight='400'
								color='#524D4D' // Directly using the desired color
							>
								Usage:&nbsp;<Text as="span" color="#6C757D">12k

								</Text>
							</Heading>
						</HStack>
						<HStack>

							<Heading
								cursor="pointer"
								mb='8px'
								fontSize='12px' // Directly using the desired font-size
								fontWeight='400'
								color='#524D4D' // Directly using the desired color
								display="flex"
							>
								Last Crawled:&nbsp;<LastCrawledFormate date={restProps.updatedAt} />
							</Heading>
						</HStack>
					</Box>
				</Box>
			</Stack>
			<AlertDialog
				isOpen={isDeleteDialogOpen}
				leastDestructiveRef={cancelRef}
				onClose={handleCancelDelete}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete chatbot?
						</AlertDialogHeader>
						<AlertDialogBody>
							You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancelRef} size='sm' onClick={handleCancelDelete}>
								Cancel
							</Button>
							<Button
								size="sm"
								colorScheme="red"
								variant="solid" onClick={handleConfirmDelete} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
