import { Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { formatDistanceStrict } from 'date-fns'
import React from 'react'
import { ChatSession } from '../../types/knowledgebase.type'
import { ThreeDotIcon } from '../Icons/ThreeDotIcon'
import { DeleteIcon } from '../Icons/DeleteIcon'
import { ReadIcon } from '../Icons/ReadIcon'
import { UnreadIcon } from '../Icons/UnreadIcon'
import style from "./ChatWindow.module.scss"

type ChatListItemProps = {
    isSelected?: boolean;
    chatSessionData: ChatSession;
    onSelectChat: (chatSession: ChatSession) => void;
    updateChatSessionReadStatus: (chatId: string, isUnread: boolean) => void
    onDeleteChat: (chatId: string) => void

}

export const ChatListItem = ({ chatSessionData, isSelected, onSelectChat, updateChatSessionReadStatus, onDeleteChat }: ChatListItemProps) => {

    // const getFormattedTime = React.useCallback((date: string) => {
    //     return formatDistance(new Date(date), new Date(), { addSuffix: true });
    // }, []);
    const getFormattedTime = React.useCallback((date: string) => {
        return formatDistanceStrict(new Date(date), new Date(), { addSuffix: true });
    }, []);

    return (
        <Flex
            direction="column"
            position="relative"
            cursor="pointer"
            px={3} py={2}
            borderBottom="1px"
            borderBottomColor="gray.100"
            bg={isSelected ? '#eee8ff' : chatSessionData.isUnread ? '#faf9ff' : 'white'}
            borderRight={"2px"}
            borderRightColor={isSelected ? "#f6f5ff" : "transparent"}
            onClick={() => !isSelected && onSelectChat(chatSessionData)}
        >
            <Text fontSize="12px" color="#6C757D" fontWeight={chatSessionData.isUnread ? '600' : '400'} mb={2}>
                {getFormattedTime(chatSessionData.updatedAt)}
            </Text>

            <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="14px" noOfLines={2} fontWeight={chatSessionData.isUnread ? '500' : '400'} color="#1A202C" mr={2}>
                    {chatSessionData.firstMessage.q}
                </Text>

                <Flex>
                    <Button
                        size="sm"
                        fontSize={12}
                        variant="outline"
                        onClick={(e) => {
                            e.stopPropagation();
                            updateChatSessionReadStatus(chatSessionData._id, !chatSessionData.isUnread)
                        }}
                        mr={2} // Add some space between the buttons
                        className={style.readBtn}
                    >
                        {chatSessionData.isUnread ? <UnreadIcon /> : <ReadIcon />}
                    </Button>
                    <Button
                        size="sm"
                        fontSize={12}
                        variant="outline"
                        colorScheme="red"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteChat(chatSessionData._id)
                        }}
                        className={style.readBtn}
                    >
                        <DeleteIcon />
                    </Button>
                </Flex>
            </Flex>
        </Flex>


    )
}