import * as React from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Progress,
	SimpleGrid,
	Input,
	Divider,
	AbsoluteCenter,
	Text,
	useToast,
	Image,
} from '@chakra-ui/react';


import { Field, Form, Formik } from 'formik';

import styles from './SignUp.module.scss';
import { authGoogle, signUp, userPaymentstatus } from '../../services/authServices';
import {
	Link,
	RouteComponentProps,
	useHistory,
	withRouter,
} from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import { GOOGLE_AUTH_ID } from '../../config';
import LoginWithGoogle from '../LoginWithGoogle/LoginWithGoogle';
import { PricingCard } from '../../components/Pricing/PricingCard';
import { SubscriptionTier, SubscriptionType } from '../../types/subscription.type';
import { PricingApiCard } from '../../components/Pricing/PricingApiCard';
import Lottie from 'lottie-react';
import animationData from './Animation.json';
import { error } from 'console';
export function validateEmailAddress(email: string) {
	return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}
interface FormValues {
	email: string;
	password: string;
}
interface SignUpProps extends RouteComponentProps {
	onSignUp: (token: string, redirectTo?: string) => void;
}


function validateEmail(value: string) {
	let error;
	if (!value) {
		error = 'Please enter your email.';
	} else if (validateEmailAddress(value)) {
		error = 'Please enter a valid email address.';
	}
	return error;
}
function validatePassword(value: string) {
	let error;
	if (!value) {
		error = 'Please enter a password.';
	}
	return error;
}
const SignUp = (props: SignUpProps) => {
	const toast = useToast();
	let history = useHistory();
	const [subscriptionTier, setSubscriptionTier] = React.useState<SubscriptionTier>('MONTHLY');
	const [singUpDiv, setSingUpDiv] = React.useState(true);
	const [priceDiv, setPriceDiv] = React.useState(false);
	const [animationDiv, setAnimationDiv] = React.useState(false);
	const [progressBar, setProgressBar] = React.useState(33);
	const [userEmail, setUserEmail] = React.useState<any>(null);
	const isCurrentPlan = React.useCallback((type: SubscriptionType) => {
		// return currentSubscription?.name === type;
	}, []);


	const responseGoogleSuccess = React.useCallback((googleResponse: any) => {

		async function googleLogin() {
			try {
				const response: any = await authGoogle({
					token: googleResponse.access_token,
				});
				props.onSignUp(response.data.access);

			} catch (error: any) {
				let title = 'Oops! Something went wrong!';
				const errors = error?.response?.data?.nonFieldErrors;
				if (errors) {
					title = errors
				}
				toast({
					title: title,
					status: "error",
					isClosable: true,
				});
			}
		}
		googleLogin();
	}, [history, props, toast])

	const responseGoogleFailure = React.useCallback((error) => {

		let title = 'Oops! Something went wrong!';
		toast({
			title: title,
			status: "error",
			isClosable: true,
		});
	}, [toast]);
	const goToDashboard = () => {
		history.push('/');
	}


	const checkSubscriptionStatus = async () => {
		try {
			const response: any = await userPaymentstatus();

			if (response?.data?.status == 'active') {

				// Only call paymentStatus if the subscription is active
				showAnimation();
				clearInterval(intervalId);
			} else {
				// Handle the case where the subscription is not active, if necessary
			}
		} catch (error) {
			console.error('Error fetching payment status:', error);
			// Handle errors if the request fails
		}
	}

	const intervalId = setInterval(() => {
		if (progressBar == 66.2) {
			checkSubscriptionStatus();
		} else if (progressBar > 66.2) {
			clearInterval(intervalId); // Clear the interval when the condition is no longer met
		}
		else {
			return null;
		}
	}, 10000);



	const singupUser = () => {
		setProgressBar(66.2);
		setSingUpDiv(false);
		setPriceDiv(true);
		checkSubscriptionStatus(); // Call the function to check the subscription status
	}
	const showAnimation = () => {
		setProgressBar(100);
		setSingUpDiv(false);
		setPriceDiv(false);
		setAnimationDiv(true)
	}

	function validatePassword(password: string): string {
		const errors: string[] = [];

		if (password.length < 8 || password.length > 16) {
			errors.push("Password must be between 8 and 16 characters long");
		}

		if (!/[A-Z]/.test(password)) {
			errors.push("Password must contain at least one uppercase letter (A-Z).");
		}

		if (!/[a-z]/.test(password)) {
			errors.push("Password must contain at least one lowercase letter (a-z).");
		}

		if (!/\d/.test(password)) {
			errors.push("Password must include at least one digit (0-9).");
		}

		if (!/[@$!%*?&]/.test(password)) {
			errors.push("Password must include at least one special character (e.g., @, $, !, %, *, ?, &, etc.).");
		}

		// Join the errors with <br /> to create line breaks
		return errors.join("<br />");
	}


	return (
		<Flex bg='#F7FAFC' h='100vh' direction='column'>
			<Flex
				shrink={0}
				shadow='base'
				w='100%'
				h='60px'
				bg='white'
				pr='10'
				pb='4'
				pt='4'
				pl='10'
				zIndex='2'
				alignItems='center'
			>
				<Logo></Logo>
			</Flex>
			<Box textAlign="center">
				<Heading
					textAlign='center'
					fontSize='30px'
					as='h3'
					mt='24px'
					mb="4px"
					color="#1A202C"
				>
					Create Account
				</Heading>
				<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D">
					Unlock 24/7 Customer & Sales Support For Your Business Today!
				</Text>
				<Box width="100%" marginTop="72px" marginBottom="78px">
					<Box position="relative" margin="0px 154px">
						{/* White Background behind Progress Bar */}
						<Box position="absolute" bottom="100%" left="0" width="100%" height="48px" backgroundColor="white" borderRadius="2px" />

						{/* Progress Bar */}
						<Progress value={progressBar} colorScheme="purple" height="10px" border="2px solid #7C2FF1" />

						{/* Dividers */}
						<Box position="absolute" top="0" width="100%" height="100%">
							<Box position="absolute" left="33%" height="100%" borderLeft="2px solid #7C2FF1" />
							<Box position="absolute" left="66%" height="100%" borderLeft="2px solid #7C2FF1" />
						</Box>

						{/* Step Labels */}
						<Flex justify="space-around" position="absolute" top="0" width="100%" mt="14px">
							<Text fontSize="16px" fontWeight="bold" color="#212529">Sign up</Text>
							<Text fontSize="16px" fontWeight="bold" color="#212529">Payment</Text>
							<Text fontSize="16px" fontWeight="bold" color="#212529">Complete</Text>
						</Flex>
					</Box>
				</Box>


			</Box>

			<Flex alignItems='center' justifyContent='center'
				display={singUpDiv === true ? "block" : "none"}
			>
				<Box
					bg='#fff'
					p='24px 24px '
					shadow='md'
					width={476}
					maxW="90vw"
					borderRadius='4px'
					m='0 auto'
					border="2px solid #DEE2E6"
					boxShadow="none"
				>
					<Heading
						textAlign='left'
						fontSize='20px'
						as='h5'
						isTruncated
						mb='4px'
						fontWeight="600"
						color="#1A202C"
					>
						Create Account
					</Heading>
					<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D" mb="24px">
						Create a Ultima Chatbot account to create the projects.
					</Text>
					<Formik
						initialValues={{ email: '', password: '' }}
						onSubmit={async (values: FormValues, actions) => {
							try {
								const response = await signUp({
									email: values.email,
									password: values.password,
									confirmPassword: values.password,
									avatarUrl: `https://api.dicebear.com/9.x/initials/svg?seed=${values.email}&radius=50&backgroundColor=D6BFFB&textColor=7C2FF1&chars=1`
								});

								setUserEmail(response.data.email);
								props.onSignUp(response.data.access);
								singupUser();
								actions.setSubmitting(false);

							} catch (error: any) {
								validatePassword(values.password);
								toast({
									title: `${error.response.data.message}`,
									status: 'error',
									isClosable: true,
								});
								actions.setSubmitting(false);
							}
						}}
					>
						{(props) => (
							<Form>
								<Field
									type='email'
									name='email'
									validate={validateEmail}
								>
									{({ field, form }: any) => (
										<FormControl
											mb='6'
											isInvalid={
												form.errors.email &&
												form.touched.email
											}
										>
											<FormLabel
												// fontSize='sm'
												htmlFor='email'
												fontSize='16px'
												fontWeight="400"
												color="#212529"
											>
												Email Address
											</FormLabel>
											<Input
												{...field}
												id='email'
												placeholder='company@mail.com'
											/>
											<FormErrorMessage>
												{form.errors.email}
											</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								<Field
									type='password'
									name='password'
									validate={validatePassword}
								>
									{({ field, form }: any) => (
										<FormControl
											mb='6'
											isInvalid={
												form.errors.password &&
												form.touched.password
											}
										>
											<FormLabel
												// fontSize='sm'
												htmlFor='password'
												fontSize='16px'
												fontWeight="400"
												color="#212529"
											>
												Password
											</FormLabel>
											<Input
												{...field}
												type='password'
												id='password'
												placeholder='********'
											/>
											<FormErrorMessage>
												<Text as="span" dangerouslySetInnerHTML={{ __html: form.errors.password }} />
											</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								<Button
									backgroundColor="#7C2FF1"
									color={"white"}
									variant='solid'
									mt={4}
									isFullWidth
									size='lg'

									type='submit'

									className='btn-hover-pri'
								>
									Create Account
								</Button>

							</Form>
						)}
					</Formik>
					<Box>
						<Box position='relative' mt="30.5px" mb="30.5px">
							<Divider color="#E2E8F0" />
							<AbsoluteCenter bg='white' px='4' color="#6C757D" fontSize="14px">
								Already have an account?
							</AbsoluteCenter>
						</Box>
						<Link to='/login'>
							<Button
								backgroundColor="white"
								color={"#7C2FF1"}
								variant='solid'
								border="2px solid"

								isFullWidth
								size='lg'
								className='btn-hover-sen'
							>
								Sign in
							</Button>
						</Link>
					</Box>
				</Box>
			</Flex>
			<Flex display={priceDiv === true ? "block" : "none"} >
				<Box
					bg='#fff'
					p='24px 24px '
					shadow='md'
					width={1040}

					borderRadius='4px'
					m='0 auto'
					marginBottom="24px"
					border="2px solid #DEE2E6"
					boxShadow="none"
				>
					<Flex>
						<Box>
							<Text as="h5" fontSize="20px" fontWeight="600" color="#1A202C" onClick={showAnimation}>
								Pricing
							</Text>
							<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D">
								Select your payment plans
							</Text>
						</Box>
					</Flex>
					<SimpleGrid columns={[1, 1, 1, 3]} spacing="6" marginTop="24px" templateColumns={{ lg: "repeat(3, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}>
						<PricingCard
							tier="MONTHLY"
							subscriptionData={{
								type: 'Standard',
								pageCount: 1000,
								tokenSize: '1M',
								projectCount: 1
							}}
							discountData={undefined}
							isCurrentSubscription={false}
							userEmail={userEmail}
						/>
						<PricingCard
							tier={subscriptionTier}
							subscriptionData={{
								type: 'Premium',
								pageCount: 2500,
								tokenSize: '2.5M',
								projectCount: 3
							}}
							discountData={undefined}
							isCurrentSubscription={false}
							userEmail={userEmail}
							isPopular
						/>
						<PricingCard
							tier={subscriptionTier}
							subscriptionData={{
								type: 'Enterprise',
								pageCount: 10000,
								tokenSize: '10M',
								projectCount: "Unlimited"
							}}
							discountData={undefined}
							isCurrentSubscription={false}
							userEmail={userEmail}
						/>
					</SimpleGrid>
					<Box position='relative' mt="20px" mb="20px">
						<Divider color="#E2E8F0" />
						<AbsoluteCenter bg='white' px='4' color="#6C757D" fontSize="14px">
							API Key Plan
						</AbsoluteCenter>
					</Box>
					<SimpleGrid columns={[1, 1, 1, 3]} spacing="6" marginTop="24px" templateColumns={{ lg: "repeat(3, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}>
						<PricingApiCard
							tier={subscriptionTier}
							subscriptionData={{
								type: 'Standard',
								pageCount: 1000,
								tokenSize: '1M',
								projectCount: 1
							}}
							discountData={undefined}
							isCurrentSubscription={false}
							userEmail={userEmail}
						/>
						<PricingApiCard
							tier={subscriptionTier}
							subscriptionData={{
								type: 'Premium',
								pageCount: 2500,
								tokenSize: '2.5M',
								projectCount: 3
							}}
							discountData={undefined}
							isCurrentSubscription={false}
							userEmail={userEmail}
							isPopular
						/>
						<PricingApiCard
							tier={subscriptionTier}
							subscriptionData={{
								type: 'Enterprise',
								pageCount: 10000,
								tokenSize: '10M',
								projectCount: "Unlimited"
							}}
							discountData={undefined}
							isCurrentSubscription={false}
							userEmail={userEmail}
						/>
					</SimpleGrid>
				</Box>

			</Flex>
			<Flex display={animationDiv === true ? "block" : "none"} >
				<Box
					bg='#fff'
					p='24px 24px '
					shadow='md'
					width={1040}
					// maxW="90vw"
					borderRadius='4px'
					m='0 auto'
					marginBottom="24px"
					border="2px solid #DEE2E6"
					boxShadow="none"
					textAlign="center"
				>
					<Box

						className={styles.animationImg}
					>
						<Lottie animationData={animationData} style={{ width: 130, height: 130 }} />
					</Box>
					{/* <Image src={animation} alt="Animated Gif" boxSize="200px" /> */}
					<Text as="p" fontSize="20px" fontWeight="600" color="#1A202C">Congratulations!</Text>
					<Text as="p" fontSize="16px" fontWeight="400" color="#6C757D">Your profile is complete.</Text>
					<Button
						backgroundColor="#7C2FF1"
						color={"white"}
						variant='solid'
						mt="24px"
						// isFullWidth
						// size='lg'
						fontSize="14px"
						className='btn-hover-pri'
						onClick={goToDashboard}
					>
						Go To Dashboard
					</Button>
				</Box>
			</Flex>

		</Flex>

	);
};
export default withRouter(SignUp);
