import React from 'react';
import { formatDistanceToNow, intervalToDuration } from 'date-fns';

const formatDateToRelativeTime = (date: string): string => {
  const now = new Date();
  const duration = intervalToDuration({ start: new Date(date), end: now });

  if (duration.weeks && duration.weeks > 0) {
    return `${duration.weeks}w ago`;
  } else if (duration.days && duration.days > 0) {
    return `${duration.days}d ago`;
  } else if (duration.hours && duration.hours > 0) {
    return `${duration.hours}h ago`;
  } else if (duration.minutes && duration.minutes > 0) {
    return `${duration.minutes}m ago`;
  } else if (duration.seconds && duration.seconds > 0) {
    return `${duration.seconds}s ago`;
  } else {
    return 'just now';
  }
};

interface MyComponentProps {
  date: string;
}

const MyComponent: React.FC<MyComponentProps> = ({ date }) => {
  const relativeTime = formatDateToRelativeTime(date);

  return (
    <div style={{ color: "#6C757D" }}>
      {relativeTime}
    </div>
  );
};

export default MyComponent;